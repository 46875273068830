import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Grid } from "../../components/grid/grid"
import { BtnContainedPrimary, LinkBody } from "../../components/button/button"

export default class DownloadPage extends React.Component {

  state = { 
    email: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  render() {

    return (
      <Layout>
      <SEO 
      title="Gracias por solicitar la descarga"
      />

        <Container>
          <Grid>
            <div class="l-start-3 l-end-11 m-start-2 m-end-6">
              <h2>Apúntate para ser el primero en probar Parquick.</h2>
              <p class="body-large">Upps, nos has pillado... Parquick todavía no está listo, pero estamos casi a punto de lanzarla.</p>
              <p>Puedes dejarnos tu email y te avisaremos en cuanto esté lista.</p>

              <form id="poll" 
              action="https://formspree.io/xeqzylge"
              method="POST">
              <div class="margintop0-5">
                  <input required type="text" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="nombre@email.com"/>
                </div>

                <div class="margintop0-5 inputWrap">
                    <input required class="checkbox" type="checkbox" id="accept" name="accept" value="Acepto la política de privacidad"/>
                    <label for="accept">Acepto la política de privacidad.</label> <LinkBody to="/../politica-de-privacidad/">Ver política de privacidad</LinkBody>
                </div>

                <div class="margintop0-5">
                  <input type="hidden" name="_next" value="https://parquick.com/descargar/gracias"/>
                  <BtnContainedPrimary>Apuntarse</BtnContainedPrimary>
                </div>
                
                
              </form>
            </div>
            
          </Grid>
        </Container>
      </Layout>
    )
  }
}